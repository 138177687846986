/*
 * @Author: 王雁鹏
 * @Date: 2024-01-20 18:08:54
 * @LastEditTime: 2024-01-24 16:27:58
 * @LastEditors: Please set LastEditors
 * @Description: 
 * @FilePath: \expressf:\web-vue\sfznscxt\src\router\index.js
 * -------------------------------------
 */
import Vue from 'vue';
import Router from 'vue-router';
const logon = () => import('../page/home/logon.vue');
const index = () => import('../page/home/home.vue');
const indexs = () => import('../page/home/index.vue');
const classification = () => import('../page/classification/classification.vue');
const informationExtraction = () => import('../page/informationExtraction/InformationExtraction.vue');
const name = () => import('../page/name/name.vue');
const split = () => import('../page/split/split.vue');
const splitReview = () => import('../page/split/splitReview.vue');
const splitConfig = () => import('../page/split/splitConfig.vue');
const splitConfig2 = () => import('../page/split/splitConfig2.vue');
const splitConfig3 = () => import('../page/split/splitConfig3.vue');
const splitConfig4 = () => import('../page/split/splitConfig4.vue');
const splitConfig5 = () => import('../page/split/splitConfig5.vue');
const endReview = () => import('../page/split/endReview.vue');
const endReview1 = () => import('../page/split/endReview1.vue');
const endReview3 = () => import('../page/split/endReview3.vue');
const endReview2 = () => import('../page/split/endReview2.vue');
const endReview4 = () => import('../page/split/endReview4.vue');
const tableExtraction = () => import('../page/tableExtraction/tableExtraction.vue');
Vue.use(Router);
let router = new Router({
  routes: [
    {
      path: '/',
      component: logon,
      name: 'index',
    },
    {
      path: "/index",
      component: indexs,
      redirect: '/home',
      name: "indexs",
      children: [
        {
          path: "/home",
          component: index,
          name: "home",
        },
        {
          path: '/name',
          component: name,
        },
        {
          path: '/split',
          component: split,
        },
        {
          path: '/splitReview',
          component: splitReview,
        },
        {
          path: '/splitConfig',
          component: splitConfig,
        },

        {
          path: '/splitConfig2',
          component: splitConfig2,
        },
        {
          path: '/splitConfig3',
          component: splitConfig3,
        },
        {
          path: '/splitConfig4',
          component: splitConfig4,
        },
        {
          path: '/splitConfig5',
          component: splitConfig5,
        },
        {
          path: '/endReview',
          component: endReview,
        },
        {
          path: '/endReview1',
          component: endReview1,
        },
        {
          path: '/endReview3',
          component: endReview3,
        },
        {
          path: '/endReview2',
          component: endReview2,
        },
        {
          path: '/endReview4',
          component: endReview4,
        },
        {
          path: '/tableExtraction',
          component: tableExtraction,
        },
        {
          path: '/informationExtraction',
          component: informationExtraction,
        },
        {
          path: '/classification',
          component: classification,
        },
      ]
    },

  ],
});
// router.beforeEach(route.beforeEach());
// router.afterEach(route.afterEach());
// // 解决联系跳转同一页面报错的bug
// const originalPush = Router.prototype.push;
// Router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch((err) => err);
// };

export default router;
