<!--
 * @Author: 王雁鹏
 * @Date: 2024-01-24 19:33:00
 * @LastEditTime: 2024-01-24 20:03:12
 * @LastEditors: Please set LastEditors
 * @Description: 
 * @FilePath: \expressf:\web-vue\sfznscxt\src\components\ComHeader.vue
 * -------------------------------------
-->
<template>
  <div class="com-header">
    <span class="logo" @click="gohome">单据智能处理系统(Al-Document)</span>

    <div class="header-right">

      <span>到期时间：{{ time }}</span>
      <el-dropdown @command="handleCommand">
        <div style="display:flex;color: #fff;margin-left: 20px;">
          <span>{{ name }}</span>
          <div class="header-model">
            <div class="header-img">
              <el-avatar class="com-avatar" size="small" :src="circleUrl"></el-avatar>
            </div>
          </div>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ComHeader',
    //对外抛出接口
    props: {

    },
    //data参数
    data() {
      return {
        size: "small",
        circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
        time: "",
        name: "",
      }
    },
    //计算属性
    computed: {

    },
    //生命周期 - 创建完成（访问当前this实例）
    created() {
      this.name = localStorage.getItem('username')
      this.time = localStorage.getItem('agingAt')

    },
    //生命周期 - 挂载完成（访问DOM元素）
    mounted() {

    },
    beforeDestroy() {

    },
    methods: {
      handleCommand(command) {
        localStorage.setItem("token", "")
        localStorage.setItem('userId',"")
        this.$router.push({ path: "/" })
      },
      gohome() {
        if (this.$route.path == '/home') {
          return
        }
        this.$router.push({ path: "/home" })
      }
    }
  }
</script>
<style lang='scss' scoped>
  /* @import url(); 引入css类 */
  .logo {
    font-size: 30px;
    font-weight: 800;
    font-style: italic;
  }

  .logo:hover {
    cursor: pointer;
  }

  .com-header {
    width: calc(100% - 80px);
    height: 60px;
    line-height: 60px;
    padding: 0px 40px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    background: #409EFF;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
  }

  .header-right {
    display: flex;
    width: 500px;
    justify-content: flex-end;

    >span {
      margin-left: 20px;
    }
  }

  .header-img {
    width: 40px;
    height: 40px;
    margin-left: 20px;
  }

  .header-model {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ::v-deep .com-avatar {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }
</style>