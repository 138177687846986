import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
// import axios from "axios";
import ElementUi from "element-ui"
import uploader from 'vue-simple-uploader';
Vue.use(uploader);
import './style/reset.css'
import "element-ui/lib/theme-chalk/index.css";

Vue.config.productionTip = false
Vue.use(ElementUi);
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
