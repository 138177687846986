<!--
 * @Author: 王雁鹏
 * @Date: 2024-01-24 12:10:09
 * @LastEditTime: 2024-01-25 09:35:59
 * @LastEditors: Please set LastEditors
 * @Description: 
 * @FilePath: \expressf:\web-vue\sfznscxt\src\App.vue
 * -------------------------------------
-->
<template>
  <div id="app">
    <!-- <ComHeader /> -->
    <router-view></router-view>
  </div>
</template>

<script>
  import ComHeader from "@/components/ComHeader.vue"
  export default {
    name: 'App',
    components: {
      ComHeader
    },
    data() {
      return {

      }
    },
    created() {


    },
  }
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  .el-card__body {
    overflow-y: scroll;
  }

  
</style>